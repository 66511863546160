import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuLink from 'components/atoms/MenuLink/MenuLink';
import { breakPoints, colors } from 'utils/variables';
import { hexToRGBA } from 'utils/lib';
import MenuContactLink from 'components/atoms/MenuContactLink/MenuContactLink';

const StyledMenuLinks = styled.div`
  position: absolute;
  display: block;
  overflow: hidden;
  transition: ${({ isOpen }) =>
    isOpen
      ? 'max-height 300ms linear 100ms, padding 200ms ease, background-color 200ms linear 100ms, box-shadow 200ms linear 100ms'
      : 'max-height 300ms ease, padding 200ms linear 200ms, background-color 200ms linear 100ms, box-shadow 200ms linear 100ms'};
  top: 0;
  left: 0;
  right: 0;
  max-height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  background-color: ${({ isOpen }) => (isOpen ? colors.white : 'transparent')};
  padding: ${({ isOpen }) => (isOpen ? '9rem 3rem 0 3rem' : '0 3rem')};
  box-shadow: ${({ isOpen }) => isOpen && `0 0.6rem 0.6rem ${hexToRGBA(colors.black, 0.16)}`};
  text-align: center;
  width: calc(100vw - 6rem);
  transform: translate(-2rem, -2rem);

  @media (min-width: ${breakPoints.tablet}) {
    background-color: transparent;
    position: static;
    display: inline-block;
    height: auto;
    width: auto;
    max-height: unset;
    padding: 0;
    box-shadow: none;
    text-align: left;
    transform: translate(0, 0);
  }
`;

const getMenuLinks = link => (
  <MenuLink title={link.text} key={link.text} link={link.url}>
    {link.text}
  </MenuLink>
);

const MenuLinks = ({ links, isOpen }) => (
  <StyledMenuLinks isOpen={isOpen}>
    {links.map(getMenuLinks)}
    <MenuContactLink link="tel: 504865436">504 865 436</MenuContactLink>
  </StyledMenuLinks>
);

MenuLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  isOpen: PropTypes.bool,
};

MenuLinks.defaultProps = {
  links: [
    {
      url: '',
      text: '',
    },
  ],
  isOpen: false,
};

export default MenuLinks;
