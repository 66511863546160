import React from 'react';
import styled from 'styled-components';
import Container from 'components/atoms/Container/Container';
import { LinkText } from 'components/atoms/Button/Button';
import { colors, breakPoints } from 'utils/variables';

const StyledContainer = styled.div`
  z-index: 10;
  position: relative;
  border-top: 2px solid #ededed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    justify-content: space-between;
    padding: 3.1rem 0;
  }
`;

const StyledCopyText = styled.span`
  color: ${colors.color6};
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 100%;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
    width: auto;
    text-align: left;
  }
`;

const StyledLinkText = styled.span`
  color: ${colors.color1};
  font-weight: 600;
  font-size: 1.2rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
  }
`;

const StyledPolicyLink = styled.span`
  font-size: 1.2rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
  }
`;

const Copyright = () => (
  <StyledContainer as={Container}>
    <StyledCopyText>
      Copyright © 2019 by{' '}
      <StyledLinkText as={LinkText} title="Kubeczek - auto serwis" to="/">
        Kubeczek - auto serwis
      </StyledLinkText>
    </StyledCopyText>
    <StyledPolicyLink as={LinkText} title="Polityka prywatności" to="/polityka-prywatnosci">
      Polityka prywatności
    </StyledPolicyLink>
  </StyledContainer>
);

export default Copyright;
