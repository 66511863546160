/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ lang, meta, metaTitle, metaDescription }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const alternativeTitle = `${site.siteMetadata.title} | ${site.siteMetadata.description}`;
  const alternativeDescription = site.siteMetadata.description;

  const notProductionMeta =
    typeof location !== 'undefined' && process.env.NO_INDEX !== 'false'
      ? [
          {
            name: 'robots',
            content: 'noindex',
          },
        ]
      : [];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle || alternativeTitle}
      meta={[
        ...notProductionMeta,
        {
          name: 'description',
          content: metaDescription || alternativeDescription,
        },
        {
          property: 'og:title',
          content: metaTitle || alternativeTitle,
        },
        {
          property: 'og:description',
          content: metaDescription || alternativeDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle || alternativeTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription || alternativeDescription,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  metaTitle: null,
  metaDescription: null,
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
};

export default SEO;
