import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LogoSVG from 'images/logo.inline.svg';
import { breakPoints } from 'utils/variables';

const StyledLogo = styled(Link)`
  display: inline-block;
  line-height: 0;

  svg {
    width: 211.5px;
    height: 50px;

    @media (min-width: ${breakPoints.tablet}) {
      width: 235px;
      height: 56.368px;
    }
  }
`;

const Logo = () => (
  <StyledLogo title="logo" to="/" aria-label="Logo" className="logo">
    <LogoSVG />
  </StyledLogo>
);

export default Logo;
