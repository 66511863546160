import React from 'react';
import PropTypes from 'prop-types';
import SEO from 'templates/SEO/SEO';
import GlobalStyle from 'templates/GlobalStyle/GlobalStyle';
import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import ThemeShape6 from 'images/themeShapes/themeShape_6.svg';
import Cookies from 'components/organisms/Cookies/Cookies';

const StyledImage = styled.img`
  position: absolute;
  top: -10%;
  left: 50%;
  width: 100%;

  @media (min-width: ${breakPoints.tablet}) {
    top: -30%;
    left: 55%;
    width: 100%;
  }

  @media (min-width: ${breakPoints.desktop}) {
    top: -1rem;
    left: 50%;
    transform: translate(-8.5rem, -34.5%);
    width: 77%;
  }
`;

const Layout = ({ children, metaTitle, metaDescription }) => (
  <>
    <GlobalStyle />
    <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
    <main>
      <StyledImage src={ThemeShape6} alt="Header background" />
      {children}
      <Cookies />
    </main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
};

Layout.defaultProps = {
  metaTitle: '',
  metaDescription: '',
};

export default Layout;
