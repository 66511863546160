import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/variables';

const StyledText = styled.p`
  color: ${({ color }) => color || colors.color5};
  font-weight: 300;
  margin: 0;
  font-size: 1.4rem;
  line-height: 2.3rem;
  letter-spacing: 0.01rem;
`;

const Text = ({ color, html, children, className }) => (
  <>
    {html ? (
      <StyledText className={`text ${className}`} color={color} dangerouslySetInnerHTML={{ __html: html }} />
    ) : (
      <StyledText className={`text ${className}`} color={color}>
        {children}
      </StyledText>
    )}
  </>
);

Text.propTypes = {
  color: PropTypes.string,
  html: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

Text.defaultProps = {
  color: null,
  html: null,
  children: null,
  className: '',
};

export default Text;
