import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%; /* =10px */
    font-family: Poppins, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
  }

  main {
    overflow-x: hidden;
    position: relative;
  }

  button,
  input,
  textarea {
    font-family: Poppins, sans-serif;
  }

  .Modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 10000;
  }
  .Modal-content {position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    max-width: 100vw;
  }
`;

export default GlobalStyle;
