import styled, { css } from 'styled-components';
import { colors, breakPoints } from 'utils/variables';

const headingStyles = css`
  color: ${props => props.color || colors.color1};
  margin: 0;
  font-weight: 500;
`;

export const H1 = styled.h1`
  ${headingStyles};
  font-size: 2.6rem;
  line-height: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 4.5rem;
    line-height: 6.5rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 5.5rem;
    line-height: 8.3rem;
  }
`;

export const H2 = styled.h2`
  ${headingStyles};
  font-size: 2.2rem;
  line-height: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 5rem;
  }
`;

export const H3 = styled.h3`
  ${headingStyles};
  letter-spacing: 0.24rem;
  font-size: 1.1rem;
  line-height: 2rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
`;

export const H4 = styled.h4`
  ${headingStyles};
  font-size: 1.4rem;
  line-height: 1.8rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
`;

export const H5 = styled.h5`
  ${headingStyles};
  font-size: 1.6rem;
  line-height: 2rem;
`;
