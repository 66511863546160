import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Scroll } from 'react-fns';
import MenuLinks from 'components/molecules/MenuLinks/MenuLinks';
import Logo from 'components/atoms/Logo/Logo';
import MenuHamburger from 'components/atoms/MenuHamburger/MenuHamburger';
import { colors, breakPoints, breakPointsNumber } from 'utils/variables';
import { hexToRGBA } from 'utils/lib';
import Container from 'components/atoms/Container/Container';

const links = [
  {
    url: '/',
    text: 'Strona główna',
  },
  {
    url: '/o-nas',
    text: 'O nas',
  },
  {
    url: '/uslugi',
    text: 'Usługi',
  },
  {
    url: '/sprzedaz-czesci',
    text: 'Sprzedaż części',
  },
  {
    url: '/kontakt',
    text: 'Kontakt',
  },
];

const StyledMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background-color: ${hexToRGBA(colors.black, 0.4)};
  opacity: ${({ isOpenMenu }) => (isOpenMenu ? '1' : '0')};
  display: ${({ isOpenMenu }) => (isOpenMenu ? 'block' : 'none')};
  transition: opacity 300ms ease;

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

const StyledMenuWrapper = styled.div`
  height: 9rem;
  left: 0;
  right: 0;
  position: relative;
  margin: 0 -1rem;

  @media (min-width: ${breakPoints.tablet}) {
    height: 11rem;
    margin: 0;
  }
`;

const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  z-index: 1000;
  height: inherit;
  padding: 0 1rem;
  left: 0;
  right: 0;
  transition: ${({ top, isOpen }) => (isOpen || top > 95 ? 'box-shadow 300ms ease, top 300ms ease, background-color 300ms ease' : 'none')};
  background-color: ${({ top }) => (top > 95 ? colors.white : 'transparent')};
  box-shadow: ${({ top }) => (top > 95 ? `0 0.3rem 0.6rem ${hexToRGBA(colors.black, 0.16)}` : 'none')};
  margin-top: ${({ top }) => (top > 95 ? '-12rem' : '0')};
  top: ${({ top }) => (top > 190 ? '12rem' : '0')};
  position: ${({ top }) => (top > 95 ? 'fixed' : 'relative')};

  @media (min-width: ${breakPoints.tablet}) {
    padding: 2rem 0 0 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 0.3rem 0 0 0;
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: column;
  }

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }

  .hamburger,
  .logo {
    position: relative;
    z-index: 10;
  }
`;

const Menu = () => {
  const [isOpenMenu, openMenu] = useState(false);

  const menuOpen = e => {
    openMenu(!isOpenMenu);
    e.target.blur();
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = isOpenMenu ? 'hidden' : 'auto';
    }
    const handleResize = () => `${window.innerWidth}` > breakPointsNumber.tablet && openMenu(false);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Scroll
      render={({ y }) => (
        <StyledMenuWrapper>
          <StyledMenu top={y} isOpen={isOpenMenu}>
            <StyledMenuContainer as={Container}>
              <Logo />
              <MenuHamburger onClick={e => menuOpen(e)} isOpenMenu={isOpenMenu} />
              <MenuLinks links={links} isOpen={isOpenMenu} />
            </StyledMenuContainer>
          </StyledMenu>
          <StyledMask isOpenMenu={isOpenMenu} onClick={() => openMenu(false)} />
        </StyledMenuWrapper>
      )}
    />
  );
};

export default Menu;
