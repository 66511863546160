import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { colors } from 'utils/variables';
import ThemeShape2 from 'images/themeShapes/themeShape_2.inline.svg';

const Button = styled.button`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  min-width: 10rem;
  border-radius: 0.3rem;
  padding: 1.1rem 2.9rem;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.color1};
  border: 0.1rem solid ${({ borderColor }) => borderColor || colors.color1};
  color: ${({ color }) => color || colors.white};

  &:hover {
    background-color: ${({ backgroundColorHover }) => backgroundColorHover || colors.white};
    border: 0.1rem solid ${({ borderColorHover }) => borderColorHover || colors.color1};
    color: ${({ colorHover }) => colorHover || colors.color1};
  }

  a {
    display: block;
    text-decoration: none;
    padding: 1.1rem 2.9rem;
    color: ${({ color }) => color || colors.white};

    &:hover {
      color: ${({ colorHover }) => colorHover || colors.color1};
    }
  }
`;

const StyledLinkButton = styled(Button)`
  padding: 0;
`;

const LinkButton = ({ to, text, backgroundColor, borderColor, color, backgroundColorHover, borderColorHover, colorHover }) => (
  <StyledLinkButton
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    color={color}
    backgroundColorHover={backgroundColorHover}
    borderColorHover={borderColorHover}
    colorHover={colorHover}
  >
    <Link title={text} to={to}>
      {text}
    </Link>
  </StyledLinkButton>
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  borderColorHover: PropTypes.string,
  colorHover: PropTypes.string,
};

LinkButton.defaultProps = {
  backgroundColor: null,
  borderColor: null,
  color: null,
  backgroundColorHover: null,
  borderColorHover: null,
  colorHover: null,
};

const linkStyles = css`
  display: inline-block;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  border-bottom: 0.1rem solid transparent;
  color: ${colors.color2};
  letter-spacing: 0.02rem;

  &:hover {
    color: ${colors.color1};
    border-bottom: 0.1rem solid ${colors.color1};
  }
`;

const LinkText = styled(Link)`
  ${linkStyles}
`;

const LinkTextButton = styled.button`
  ${linkStyles}
`;

const StyledShapeLink = styled(LinkText)`
  display: inline-flex;
  align-items: center;
  color: ${colors.color1};
  font-weight: 600;
  letter-spacing: 0.01rem;
  border-bottom: 0;
  transition: color 150ms ease;
  background-color: transparent;

  svg {
    margin-left: 1.5rem;
    transition: transform 300ms ease;
  }

  &:hover {
    color: ${colors.color3};
    border-bottom: 0;

    svg {
      transform: translateX(1rem);
    }
  }
`;

const ShapeLink = ({ to, text }) => (
  <StyledShapeLink as={LinkText} title={text} to={to}>
    {text}
    <ThemeShape2 />
  </StyledShapeLink>
);

ShapeLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ShapeLinkButton = ({ text, onClick }) => (
  <StyledShapeLink onClick={onClick} as={LinkTextButton}>
    {text}
    <ThemeShape2 />
  </StyledShapeLink>
);

ShapeLinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ShapeLinkButton.defaultProps = {
  onClick: () => {},
};

export { LinkButton, LinkText, ShapeLink, ShapeLinkButton };
export default Button;
