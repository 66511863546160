import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'images/phone-icon.inline.svg';
import { breakPoints, colors } from 'utils/variables';

const StyledMenuContactLink = styled.a`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 2rem 0.1rem;
  line-height: 2.2rem;
  font-weight: 600;
  color: ${colors.color3};

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    margin-left: 1.5rem;
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    transform: translate(-0.6rem, 0.2rem);
  }
`;

const MenuContactLink = ({ link, children }) => (
  <StyledMenuContactLink title={children} href={link}>
    <Icon />
    {children}
  </StyledMenuContactLink>
);

MenuContactLink.propTypes = {
  link: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MenuContactLink.defaultProps = {
  link: '/',
  children: null,
};

export default MenuContactLink;
