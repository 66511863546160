/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors, breakPoints } from 'utils/variables';
import { hexToRGBA } from 'utils/lib';
import Text from 'components/atoms/Typography';
import Container from 'components/atoms/Container/Container';

const StyledWrapper = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  margin: 0;
  padding: 2rem 0;
  background-color: ${hexToRGBA(colors.black, 0.9)};
  box-shadow: 0 0.3rem 0.6rem ${hexToRGBA(colors.black, 0.16)};
  z-index: 100;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
`;

const StyledText = styled.div`
  max-width: calc(100% - 3rem);

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 55rem;
  }

  a {
    color: ${colors.white};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

const StyledClose = styled.div`
  color: ${colors.white};
  font-size: 3rem;
  position: absolute;
  right: 0%;
  top: 0;
  line-height: 3rem;
  width: 3rem;
  text-align: center;
  cursor: pointer;
`;

const Cookies = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    typeof localStorage !== 'undefined' && setShow(!localStorage.getItem('cookies'));
  });

  const closeCookies = () => {
    typeof localStorage !== 'undefined' && localStorage.setItem('cookies', 'accepted');
    setShow(false);
  };

  return (
    <StyledWrapper show={show}>
      <Container>
        <StyledText
          as={Text}
          color={colors.white}
          html={
            'Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.<br/>Zobacz naszą <a title="politykę prywatności" href="/polityka-prywatnosci">politykę prywatności</a>.'
          }
        />
        <StyledClose onClick={closeCookies}>&times;</StyledClose>
      </Container>
    </StyledWrapper>
  );
};

export default Cookies;
