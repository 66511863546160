import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'components/atoms/Container/Container';
import { colors, breakPoints } from 'utils/variables';
import Logo from 'components/atoms/Logo/Logo';
import Text, { H4 } from 'components/atoms/Typography';
import { LinkText } from 'components/atoms/Button/Button';
import Copyright from 'components/molecules/Copyright/Copyright';
import FooterMapShape from 'images/footer-map-shape.inline.svg';
import ThemeShape7 from 'images/themeShapes/themeShape_7.svg';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';

const StyledFooter = styled.footer`
  padding: 5rem 0 0 0;
  position: relative;
  overflow-y: hidden;

  @media (min-width: ${breakPoints.desktop}) {
    padding: 15rem 0 0 0;
  }
`;

const StyledContainer = styled(Container)`
  display: none;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakPoints.tablet}) {
    display: flex;
  }

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }
`;

const StyledInformation = styled.div`
  .info {
    font-weight: 600;
    line-height: 5rem;
    margin-bottom: 38px;
  }

  .nip,
  .email,
  .phone {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.8rem;
    display: flex;
    margin: 0.3rem 0;
  }
`;

const StyledSection = styled.div`
  position: relative;
  z-index: 10;

  &.left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: ${breakPoints.desktop}) {
      width: 31.5rem;
    }

    svg {
      margin-bottom: 4.3rem;
    }
  }
  &.right {
    width: 100%;
    margin: 4rem 0 6rem 0;

    @media (min-width: ${breakPoints.desktop}) {
      width: 45rem;
      margin: 0 0 13.2rem 0;
    }

    h4 {
      font-weight: 600;
      line-height: 5rem;
      margin: 0 0 0 -0.3rem;

      @media (min-width: ${breakPoints.desktop}) {
        margin: 0 0 3.7rem -0.3rem;
      }
    }

    .text {
      line-height: 2rem;
      font-weight: 400;
    }

    .firm-name {
      font-weight: 600;
    }
  }
`;

const StyledLinkText = styled.a`
  color: ${colors.color6};
  font-weight: 300;
  font-size: 1.5rem;
  border: 0;
  display: block;
  text-decoration: none;

  &:hover {
    border: 0;
    color: ${colors.color1};
  }
`;

const StyledAddress = styled.div`
  position: relative;
  z-index: 10;
`;

const StyledMap = styled.div`
  z-index: 12;
  position: relative;
  width: 50%;
  left: 0;
  right: 0;
  transform: translate(34.5rem, -35rem);
  display: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: block;
    width: 360px;
    height: 345px;
    transform: translate(23.1rem, -24.1rem);
  }

  img {
    transition: transform 300ms ease, opacity 500ms ease !important;
  }

  &:hover img {
    transform: scale(1.03);
  }
`;

const StyledMapShape = styled.svg`
  position: absolute;
  width: 70%;
  transform: translate(30rem, -38rem);
  display: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: block;
    width: 480.31px;
    height: 456.97px;
    transform: translate(20rem, -25.5rem);
  }
`;

const StyledLinks = styled.div`
  display: none;
  z-index: 10;
  position: relative;
  justify-content: flex-end;
  margin-bottom: 1.7rem;

  @media (min-width: ${breakPoints.desktop}) {
    display: flex;
  }
`;

const StyledFooterLink = styled(Link)`
  color: ${colors.color6};
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: block;
  text-decoration: none;
  margin: 1rem 1.67rem 1rem 1.3rem;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${colors.color1};
  }
`;

const StyledShape = styled.img`
  position: absolute;

  @media (min-width: ${breakPoints.desktop}) {
    top: -10rem;
    right: 50%;
    transform: translate(-18rem, 0%);
    width: calc(52%);
  }
`;

const StyledIframe = styled.iframe`
  border: none;
  overflow: hidden;
`;

const Links = [
  {
    url: '/',
    text: 'Strona główna',
  },
  {
    url: '/o-nas',
    text: 'O nas',
  },
  {
    url: '/uslugi',
    text: 'Usługi',
  },
  {
    url: '/sprzedaz-czesci',
    text: 'Sprzedaż części',
  },
  {
    url: '/kontakt',
    text: 'Kontakt',
  },
];

const getLinks = link => (
  <StyledFooterLink as={LinkText} title={link.text} to={link.url} key={link.url}>
    {link.text}
  </StyledFooterLink>
);

const Footer = ({ short }) => {
  const { MapImage } = useStaticQuery(graphql`
    query {
      MapImage: file(relativePath: { regex: "/footer-map.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledFooter>
      <StyledContainer>
        <StyledSection className="left">
          <Logo />
          <StyledIframe
            title="fb-widget"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAuto-Serwis-Kubeczek-107038131461353%2F&tabs=timeline&width=340&height=270&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="300"
            height="270"
            scrolling="no"
            frameBorder="0"
            allowFullScreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </StyledSection>
        <StyledInformation>
          <H4 color={colors.color1} className="info">
            Informacje
          </H4>
          <span className="nip">
            {' '}
            NIP:&nbsp;
            <StyledLinkText title="642-244-95-01">&nbsp;642-244-95-01</StyledLinkText>
          </span>
          <span className="email">
            {' '}
            E-mail:&nbsp;
            <StyledLinkText title="kontakt@serwis-kubeczek.pl" href="mailto: kontakt@serwis-kubeczek.pl">
              &nbsp;kontakt@serwis-kubeczek.pl
            </StyledLinkText>
          </span>
          <span className="phone">
            Telefon:&nbsp;
            <StyledLinkText title="504 865 436" href="tel: 504865436">
              &nbsp;504 865 436,{' '}
            </StyledLinkText>
            <StyledLinkText title="32 42 49 242" href="tel: 324249242">
              &nbsp;32 42 49 242
            </StyledLinkText>
          </span>
        </StyledInformation>
        {!short && (
          <StyledSection className="right">
            <StyledAddress>
              <H4 color={colors.color1}>Gdzie nas znajdziesz?</H4>
              <Text color={colors.color1} className="firm-name">
                Auto-Części Adam Kubeczek
              </Text>
              <Text color={colors.color2}>
                Ul. Wodzisławska 100
                <br />
                44-218 Rybnik
              </Text>
            </StyledAddress>
            <a
              target="_blank"
              rel="noopener noreferrer"
              title="mapa"
              href="https://www.google.pl/maps/place/Auto-Cz%C4%99%C5%9Bci+KUBECZEK/@50.0842229,18.5289465,17.22z/data=!4m5!3m4!1s0x47114f1f433ceebf:0x723ed5306469e8c3!8m2!3d50.0843178!4d18.528205"
            >
              <StyledMap
                as={Img}
                fluid={MapImage.childImageSharp.fluid}
                alt="header"
                objectFit="contain"
                objectPosition="50% 50%"
                style={{ position: 'absolute', overflow: 'visible' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </a>
            <StyledMapShape as={FooterMapShape} />
          </StyledSection>
        )}
      </StyledContainer>
      <StyledLinks as={Container}>{Links.map(getLinks)}</StyledLinks>
      <Copyright />
      <StyledShape src={ThemeShape7} alt="Footer shape" />
    </StyledFooter>
  );
};

Footer.propTypes = {
  short: PropTypes.bool,
};

Footer.defaultProps = {
  short: false,
};

export default Footer;
