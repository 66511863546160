// Colors
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  error: '#CC5D5D',
  success: '#719B6D',
  color1: '#1F3557',
  color2: '#9297AC',
  color3: '#144EA7',
  color4: '#EDF4FF',
  color5: '#5C5C5C',
  color6: '#6D738C',
  color7: '#F7F7F8',
};

export const breakPointsNumber = {
  tablet: 768,
  desktop: 1300,
  fullsize: 1600,
};

export const breakPoints = {
  tablet: `${breakPointsNumber.tablet}px`,
  desktop: `${breakPointsNumber.desktop}px`,
  fullsize: `${breakPointsNumber.fullsize}px`,
};

export default null;
