import styled from 'styled-components';
import { breakPoints } from 'utils/variables';

const Container = styled.div`
  margin: 0 1rem;
  position: relative;
  width: calc(100% - 2rem);

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 auto;
    width: 720px;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 1200px;
  }
`;

export default Container;
