import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuHamburgerSVG from 'images/hamburger.inline.svg';
import { breakPoints } from 'utils/variables';

const StyledMenuHamburger = styled.div`
  display: inline-block;
  position: relative;

  svg {
    width: 40px;
    height: 40px;

    line {
      transition: transform 300ms ease;
    }

    [data-name='Line 1'] {
      transform: ${({ isOpenMenu }) => (isOpenMenu ? 'translate(1.45rem, 0.75rem)' : 'translate(0.95rem, 2.7455rem)')};
    }

    [data-name='Line 2'] {
      transform: ${({ isOpenMenu }) => (isOpenMenu ? 'translate(0.5rem, 2.05rem)' : 'translate(0.95rem, 2.0415rem)')};
    }

    [data-name='Line 3'] {
      transform: ${({ isOpenMenu }) => (isOpenMenu ? 'translate(1.2rem, 3.5rem)' : 'translate(0.95rem, 1.3375rem)')};
    }
  }

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

const MenuHamburger = ({ onClick, isOpenMenu }) => (
  <StyledMenuHamburger onClick={onClick} isOpenMenu={isOpenMenu} className="hamburger">
    <MenuHamburgerSVG />
  </StyledMenuHamburger>
);

MenuHamburger.propTypes = {
  onClick: PropTypes.func,
  isOpenMenu: PropTypes.bool,
};

MenuHamburger.defaultProps = {
  onClick: () => {},
  isOpenMenu: false,
};

export default MenuHamburger;
