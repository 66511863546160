import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakPoints, colors } from 'utils/variables';

const StyledMenuLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 2rem 0;
  line-height: 2.2rem;
  font-weight: 400;
  color: ${colors.color1};

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 1.25rem;
    display: inline-block;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0 2.24rem;
  }

  &:first-child {
    margin-left: 0;
    margin-top: 1.8rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 4.7rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin-bottom: 0;
    }
  }

  &.active {
    font-weight: 600;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const MenuLink = ({ link, title, children }) => (
  <StyledMenuLink title={title} to={link} activeClassName="active">
    {children}
  </StyledMenuLink>
);

MenuLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MenuLink.defaultProps = {
  link: '/',
  title: '',
  children: null,
};

export default MenuLink;
